function openFullscreen(video) {
  if (video.requestFullscreen) {
    video.requestFullscreen();
  } else if (video.webkitRequestFullscreen) {
    // Chrome, Safari and Opera
    video.webkitRequestFullscreen();
  } else if (video.msRequestFullscreen) {
    // IE/Edge
    video.msRequestFullscreen();
  } else if (video.mozRequestFullScreen) {
    // Firefox
    video.mozRequestFullScreen();
  } else {
    // alert("Your browser does not support fullscreen mode.");
  }
}

function showImage(img) {
  img.parentNode.querySelector(".placeholder").classList.add("hide");
}

const loadImgs = Array.from(document.querySelectorAll(".load-img"));
if (loadImgs.length > 0) {
  loadImgs.forEach((img) => {
    const loadImag = new Image();
    loadImag.src = img.getAttribute("src");
    loadImag.onload = () => {
      showImage(img);
    };
  });
}
const videoTrailer = document.getElementById("video-trailer");

function handleFullscreenChange() {
  const isFullscreen =
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement;

  if (!isFullscreen) {
    videoTrailer.classList.remove("show");
    videoTrailer.style.display = "none";
    window.setTimeout(() => {
      videoTrailer.pause();
    }, 500);
  } else {
    videoTrailer.play();
    videoTrailer.style.display = "block";
    videoTrailer.classList.add("show");
  }
}

window.addEventListener("fullscreenchange", handleFullscreenChange);
window.addEventListener("webkitfullscreenchange", handleFullscreenChange);
window.addEventListener("mozfullscreenchange", handleFullscreenChange);
window.addEventListener("MSFullscreenChange", handleFullscreenChange);

document.querySelector(".trailer-btn").addEventListener("click", () => {
  document.getElementById("video-trailer").play();
  openFullscreen(document.getElementById("video-trailer"));
});

const hash = window.location.hash ? window.location.hash.substring(1) : "en";

let srcVideo = "";

if (hash === "fr") {
  srcVideo =
    "https://res.cloudinary.com/neuvieme-page/video/upload/f_auto/v1682421585/ysl-strange-way-of-life/FINAL-TEASER_30SEC_V2.mp4";
} else if (hash === "en") {
  srcVideo =
    "https://res.cloudinary.com/neuvieme-page/video/upload/v1682437556/ysl-strange-way-of-life/FINAL-TEASER_30SEC_16X9_EN.mp4";
} else {
  srcVideo =
    "https://res.cloudinary.com/neuvieme-page/video/upload/v1682437556/ysl-strange-way-of-life/FINAL-TEASER_30SEC_16X9_EN.mp4";
}

videoTrailer.src = srcVideo;
